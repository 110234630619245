Vue.component('singleItemComponent',{
    data:()=>{
        return {
            itemInCar:0,
            Qty:1,
            favorite:false
        };
    },
    updated: function () {
        if(this.item && !this.item.isCalculate)
            this.$store.dispatch('updatePrices', [this.item]);
    },
    watch: {
        item: function () {
            this.favorite = this.$store.getters.getFavorites.indexOf(this.item.Code) != -1;
        }
    },
    computed:{
        item:function () {
            let itemArg = this.$store.getters.getItemByCode(this.$route.params.id);
            if(itemArg) {
                //this.favorite = this.$store.getters.getFavorites.indexOf(itemArg.Code) != -1;
                let cat = this.$store.getters.getCategoryDataByCode(itemArg.Classification.split(",")[0]);
                //console.log(itemArg.Classification.split(","));
                if((cat && this.$store.state.cat_selected && cat.code != this.$store.state.cat_selected.code) || (cat && !this.$store.state.cat_selected))
                    this.$store.dispatch('updateCategory',cat);
                return itemArg;
            }
            return null;
        },
        currentItemPrice: function () {
            let test = this.$store.state.itemTimeFlag;
            let test2 = this.$store.state.itemUpdate;
            if(test != test2 || test == test2)
                test = test2;
            return this.$store.getters.showItemPrice(this.item);
        },
        relatedItems:function () {
            //console.log("ReloadComputed",!this.loadingPrice, this.$store.state.itemsFullLoader, this.item, this.item.RelatedItems);
            if(this.item && this.item.RelatedItems){
                let related = [];
                for(let relatedCode of this.item.RelatedItems.split(',')){
                    let storeItem = this.$store.getters.getItemByCode(relatedCode);
                    if(storeItem)
                        related.push(storeItem);
                }
                return related;
            }
            return [];
        }
    },
    methods:{
        ItemComponentAddToCar:function () {
            if(isNaN(this.Qty) || this.Qty == 0) {
                this.Qty = 0;
                return;
            }
            this.$store.dispatch('ItemComponentAddToCar',{id:this.item.Code,cant:this.Qty});
        },
        itemQtyIncrement:function () {
            if(Number.isInteger(this.Qty))
                this.Qty += 1;
            else
                this.Qty = 1;

        },
        itemQtyDecrement:function () {
            if(this.Qty)
                this.Qty -= 1;
            else
                this.Qty = 0;
        },
        goBack:function () {
            this.$store.dispatch('updateCategory',null);
            this.$router.go(-1);
        }
    },
    template:`<div class="row produktliste" v-if="item">
                <div class="col l12 m12 s12">
                    <div class="card">
                      <div class="container" style="padding:0;margin:0;">
                        <div class="row">
                          <div class="col m4 s12">
                            <div class="img-view">
                                <itemSliderComponent :item="item" :container="true" :showCarusel="true" :key="item.Code"></itemSliderComponent>
                            </div>
                          </div>
                          <div class="col m8 s12">
                            <div class="row">
                                <div class="col m9 s12">
                                    <span class="tit-producto">{{item.Name}}</span>
                                </div>
                                <div class="col m3 s12" style="text-align: right;">
                                      <img v-if="item.SpecialPrice" src="../static/ecommerce/assets/img/especial.png" width="45" alt="Special Price" class="circle responsive-img" >
                                      <img v-if="item.Ecofriendly" src="../static/ecommerce/assets/img/eco.png" width="45" alt="Ecofriendly" class="circle responsive-img" >                                  
                                </div>
                            </div>    
                            <div class="row">
                                <p class="desc-producto" v-if="item.Comment">{{item.Comment}}</p>
                                <p class="desc-producto" v-else>Artículo sin descripción.</p>
                            </div>
                            <div class="row" style="background-color: #fbef9e;">
                                <table width="50%" border="0" align="right" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>          
                                      <td width="80%">
                                          <span class="col m8 s12" style="background-color:#fbef9e;font-size:12px;padding:11px;">
                                              <strong>Cod. {{item.Code}}  / </strong>
                                              <strong>Empaque {{item.Unit}}  / </strong>
                                              <strong>Art. {{item.AlternativeCode}}/ </strong>
                                          </span>
                                          <span class="col m4 s12" style="background-color: #fbef9e; font-size: 12px;padding: 8px;"><strong class="pricered">$ {{currentItemPrice}}</strong></span>
                                      </td>
                                      <td width="15%">
                                          <table width="100%" border="0" cellspacing="0" cellpadding="0">
                                              <tbody>
                                                <tr>
                                                  <td width="20" align="center" @click.stop="itemQtyIncrement"><i class="fas fa-angle-up"></i> </td>
                                                  <td><input :ref="'carCant-'+item.Code" v-model="Qty" class="inputch" type="text"></td>
                                                  <td width="20" align="center" @click.stop="itemQtyDecrement"><i class="fas fa-angle-down"></i> </td>                                                    
                                                </tr>
                                              </tbody>
                                          </table>
                                      </td>
                                      <td width="20" @click.stop="ItemComponentAddToCar(item)"><i class="fas fa-shopping-bag fa-2x"></i></td>
                                      <td width="20" align="center" title="Regresar. al Listado" @click="goBack"><i class="fas fa-arrow-left fa-2x"></i></td>
                                    </tr>
                                  </tbody>
                                </table>
                                <br>
                            </div>
                          </div>
                          <div :key="item.Code" class="col s10 home-produktliste offset-s1" v-if="relatedItems.length>0">
                              <homeItemCardComponent :items="relatedItems" :section="{ title:tr('Related Items') } "/>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>`
});
